<template>
  <div class="menu-creation ecommerce-application">
    <div
      v-if="storeList"
      class="wrap-online-order-section"
    >
      <no-data-available
        v-if="!optionsLoaded.products"
        :type="'product'"
      />
      <div
        v-if="optionsLoaded.products"
        class="row"
      >
        <div
          class="col-md-12"
          :class="{ 'scroll-products': !isMobile }"
        >
          <section id="ecommerce-header">
            <div class="row">
              <div class="col-sm-12">
                <div class="ecommerce-header-items">
                  <div class="d-flex justify-content-between w-100">
                    <div class="search-results">
                      {{ $t('Showing') }} {{ filteredStore.length }} {{ $t('of') }} {{ storeList.products ? storeList.products.length : 0 }} {{ $t('results') }}
                    </div>
                    <div
                      v-if="filteredStore.length != (storeList.products ? storeList.products.length : 0)"
                      class="d-inline"
                    >
                      <p
                        class="mb-0 text-underline"
                        @click="clearFilter"
                      >
                        {{ $t('Clear Filters') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="ecommerce-searchbar mt-1">
            <b-row>
              <b-col cols="12">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Search..."
                    class="search-product"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="SearchIcon"
                      class="text-muted"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </div>
          <swiper
            v-if="!isMobile && categoryChunks.length > 0"
            class="swiper-progress wrapper-menu-options mt-1"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide
              v-for="(item, index) in categoryChunks"
              :key="index"
            >
              <div
                class="card ecommerce-card menu-category-offer-cards mb-1"
                :class="{ selected: item.isSelected }"
                @click="categorySelection(item)"
              >
                <div class="item-img text-center p-0 no-padding-section relative menu-type">
                  <a
                    href="javascript: void(0);"
                    class="w-100 h-100"
                  >
                    <img
                      :src="$helpers.imageHelper(item.picture)"
                      class="card-img-top w-100 h-100 img-fluid"
                    >
                  </a>
                  <a
                    v-b-tooltip.hover
                    :title="item.store_category_name"
                    class="text-center category-name"
                  >
                    {{ item.store_category_name }}
                  </a>
                </div>
              </div>
            </swiper-slide>
            <div
              slot="button-next"
              class="swiper-button-next"
            />
            <div
              slot="button-prev"
              class="swiper-button-prev"
            />
            <div
              slot="pagination"
              class="swiper-pagination"
            />
          </swiper>
          <section
            v-if="storeList"
            :class="isMobile ? 'responsive-list-view' : itemView"
          >
            <app-collapse
              v-if="isMobile"
              accordion
            >
              <app-collapse-item
                v-for="(categoryGroup, index) in storeCategoryGroups"
                :key="index"
                :title="`${categoryGroup.store_category_name} (${filteredStore.filter(x => x.store_category_id === categoryGroup.id).length})`"
              >
                <product-section
                  v-for="product in filteredStore.filter(x => x.store_category_id === categoryGroup.id)"
                  :key="product.id"
                  class="ecommerce-cards-product"
                  :product="product"
                  :that="that"
                  @toggle-show="toggleShow"
                />
              </app-collapse-item>
            </app-collapse>
            <product-section
              v-for="product in filteredStore"
              v-else
              :key="product.id"
              :product="product"
              :that="that"
              @toggle-show="toggleShow"
            />
          </section>
        </div>
      </div>
    </div>
    <b-modal
      id="product-add-ons"
      v-model="showAddOns"
      :title="$t('Would you like to add any of this?')"
      :no-close-on-backdrop="true"
      @hidden="showAddOns = false"
      @ok="addItemsOns"
    >
      <div
        v-if="productAddOns && productAddOns.length > 0"
        class="col-md-12"
      >
        <b-form-checkbox-group
          v-model="selectedAddOns"
          name="event-filter"
          stacked
        >
          <b-form-checkbox
            v-for="item in productAddOns"
            :key="item.id"
            name="event-filter"
            :value="item.id"
            class="mb-1"
            :class="`custom-control-primary`"
          >
            {{ item.addon_name }} at {{ storeList.currency_symbol }}{{ item.price }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>
    </b-modal>
    <b-modal
      id="product-variations"
      v-model="showVariations"
      :title="$t('Please choose the variations and addons')"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="showVariations = false"
      @ok="setItemAddOnData(lastAddedProduct, false)"
    >
      <div
        v-if="lastAddedProduct"
        class="w-100 border-bottom mb-1"
      >
        <h4>{{ lastAddedProduct.product ? lastAddedProduct.product.item_name : lastAddedProduct.item_name }}</h4>
        <p>{{ lastAddedProduct.product ? lastAddedProduct.product.item_description : lastAddedProduct.item_description }}</p>
      </div>
      <div
        v-if="productVariations && productVariations.length > 0"
        class="col-md-12"
      >
        <div
          v-for="item in productVariations"
          :key="item.id"
        >
          <b-form-radio-group
            v-if="!item.multiple"
            v-model="item.selectedVariations"
            :name="'variation-filter-' + item.id"
            stacked
          >
            <label
              v-if="item.variation_values && item.variation_values.length > 0"
              class="font-16 w-100 text-center"
            >
              {{ item.title }} <span
                v-if="!item.optional"
                class="ml-2 small"
              >({{ $t('Required') }})</span>
            </label>
            <b-form-radio
              v-for="data in item.variation_values"
              :key="data.id"
              :name="'variation-filter-' + item.id"
              :value="data.id"
              class="mb-1"
              :class="`custom-control-primary custom-radio-right`"
            >
              <div class="w-100 d-flex justify-content-between border-bottom custom-div-wrapper">
                <span>{{ data.variation_option_id }}</span>
                <span v-if="canShowPrice(data.option_price)">{{ storeList.currency_symbol }}{{ data.option_price }}</span>
              </div>
            </b-form-radio>
          </b-form-radio-group>
          <b-form-checkbox-group
            v-else
            v-model="item.selectedVariations"
            :name="'variation-filter-' + item.id"
            stacked
          >
            <label
              v-if="item.variation_values && item.variation_values.length > 0"
              class="font-16 w-100 text-center"
            >
              {{ item.title }} <span
                v-if="!item.optional"
                class="ml-2 small"
              >({{ $t('Required') }})</span>
            </label>
            <b-form-checkbox
              v-for="data in item.variation_values"
              :key="data.id"
              :name="'variation-filter-' + item.id"
              :value="data.id"
              class="mb-1"
              :class="`custom-control-primary custom-radio-right`"
            >
              <div class="w-100 d-flex justify-content-between border-bottom custom-div-wrapper">
                <span>{{ data.variation_option_id }}</span>
                <span v-if="canShowPrice(data.option_price)">{{ storeList.currency_symbol }}{{ data.option_price }}</span>
              </div>
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
      </div>
      <div
        v-if="productAddOns && productAddOns.length > 0"
        class="col-md-12 mt-2"
      >
        <h5 class="text-center">
          {{ $t('Extra Additions') }} <span class="ml-25 small">({{ $t('Optional') }})</span>
        </h5>
        <b-form-checkbox-group
          v-model="selectedAddOns"
          name="event-filter"
          stacked
        >
          <b-form-checkbox
            v-for="item in productAddOns"
            :key="item.id"
            name="event-filter"
            :value="item.id"
            class="mb-1"
            :class="`custom-control-primary custom-radio-right`"
          >
            <div class="w-100 d-flex justify-content-between border-bottom custom-div-wrapper">
              <span>{{ item.addon_name }}</span>
              <span>{{ storeList.currency_symbol }}{{ item.price }}</span>
            </div>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>
      <modal-footer
        :ok-text="'Add Items'"
        @hidden="showVariations = false"
        @ok="setItemAddOnData(lastAddedProduct, false)"
      />
    </b-modal>
    <b-modal
      v-model="repeatLastItem"
      :title="$t('Repeat last used customization?')"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="repeatLastItem = false"
    >
      <div class="row">
        <div class="col-md-6 text-center my-1">
          <b-button
            variant="primary"
            @click="letMeChoose"
          >
            {{ $t('Let me choose') }}
          </b-button>
        </div>
        <div class="col-md-6 text-center my-1">
          <b-button
            variant="primary"
            @click="repeatLastItemAdded"
          >
            {{ $t('Repeat last') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */

import { BFormCheckboxGroup, BFormCheckbox, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BButton, BModal } from 'bootstrap-vue'
import NoDataAvailable from '@/views/users/shared/NoDataAvailable.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ModalFooter from '@/@core/components/modal-footer/ModalFooter.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from '../useStore'
import ProductSection from './ProductSection.vue'

export default {
  components: {
    ModalFooter,
    NoDataAvailable,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormRadioGroup,
    AppCollapse,
    AppCollapseItem,
    BFormRadio,
    BRow,
    BModal,
    BCol,
    BInputGroup,
    BFormInput,
    Swiper,
    SwiperSlide,
    BInputGroupAppend,
    BButton,
    ProductSection,
  },
  props: {
    storeList: {
      type: [Object, Array],
      default: () => {},
    },
    phoneUserInfo: {
      type: Object,
      default: () => {},
    },
    editStoreInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showAddOns: false,
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          770: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 8,
            spaceBetween: 30,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      lastAddedProduct: null,
      repeatLastItem: false,
      chooseManually: false,
      spinDefaultObj: {},
      productAddOns: [],
      selectedAddOns: [],
      optionsLoaded: {
        products: false,
      },
      searchQuery: '',
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      userCartItems: [],
      originalCartItems: [],
      productVariations: [],
      showVariations: false,
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters['app/windowSize'] <= 767
    },
    that() {
      return this
    },
    filteredStore() {
      let { products } = this.storeList
      if (this.searchQuery) {
        products = this.storeList.products.filter(x => x.item_name && x.item_name.toLowerCase().includes(this.searchQuery.toLowerCase()))
      }
      if (!this.isMobile) {
        const selectedCategoryIds = this.storeList.categories.filter(x => x.isSelected).map(x => x.id)
        if (selectedCategoryIds.length > 0) {
          products = products.filter(x => selectedCategoryIds.includes(x.store_category_id))
        }
      }
      return products
    },
    storeCategoryGroups() {
      if (!this.storeList) return []
      if (!this.storeList.categories) return []
      return this.storeList.categories
    },
    categoryChunks() {
      if (!this.storeList.categories) return []
      return this.storeList.categories
      // return this.splitArrayData(this.storeList.categories, 7)
    },
  },
  watch: {
    storeList: {
      immediate: true,
      handler() {
        if (!this.optionsLoaded.products && this.storeList && this.storeList.products) {
          this.optionsLoaded.products = this.storeList.products.length > 0
        }
      },
    },
  },
  mounted() {
    this.getCartList()
    window.that = this
  },
  methods: {
    splitArrayData(array, splitCount) {
      const clonedArray = window.SuperApp.actions.cloneDeep(array)
      return new Array(Math.ceil(clonedArray.length / splitCount)).fill().map(() => clonedArray.splice(0, splitCount))
    },
    canShowPrice(price) {
      if (!price) return false
      // eslint-disable-next-line radix
      return parseInt(price) !== 0
    },
    clearFilter() {
      this.searchQuery = ''
      this.storeList.categories = this.storeList.categories.map(x => ({
        ...x,
        isSelected: false,
      }))
    },
    categorySelection(item) {
      const isExist = this.storeList.categories.find(x => x.id === item.id)
      if (isExist) {
        isExist.isSelected = !isExist.isSelected
      }
    },
    toggleShow(product) {
      const isExist = this.storeList.products.find(x => x.id === product.id)
      if (isExist) {
        isExist.showFull = !isExist.showFull
      }
    },
    getText(text) {
      return text.slice(0, 50)
    },
    canShowLabel(text) {
      return text && `${text}`.length > 50
    },
    isAddedToCart(product) {
      return product && this.userCartItems && this.userCartItems.carts && this.userCartItems.carts.find(x => x.product && x.product.id === product.id)
    },
    addedProduct(product) {
      const products = this.userCartItems.carts.filter(x => x.product && x.product.id === product.id)
      return products[products.length - 1]
    },
    removeItemFromCart(product) {
      const cartProduct = this.userCartItems.carts.find(x => x.product && x.product.id === product.id)
      const formData = new FormData()
      formData.append('cart_id', cartProduct.id)
      formData.append('user_id', cartProduct.user_id)

      const { storeRemoveCart } = useStoreUi()
      showLoader()
      storeRemoveCart(formData)
        .then(({ data }) => {
          if (data) {
            data.total_price_original = window.SuperApp.actions.cloneDeep(data.total_price)
            this.userCartItems = data
            this.originalCartItems = window.SuperApp.actions.cloneDeep(data)
            this.$emit('cart-info', this.userCartItems)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    showAddOnsPopup() {
      this.showAddOns = true
      this.selectedAddOns = []
    },
    showVariationsPopup() {
      this.showVariations = true
    },
    addItemsOns() {
      this.addItemToCart(this.lastAddedProduct, this.selectedAddOns.join(','))
    },
    setItemAddOnData(props, checkVariations = true) {
      const addOns = props.product ? props.product.itemsaddon : props.itemsaddon
      const variations = props.product ? props.product.variation_prices : props.variation_prices
      const allVariations = props.product ? props.product.variations : props.variations
      let isAnyAvailable = false
      if (checkVariations) {
        this.productVariations = []
        this.productAddOns = []
      }
      if (variations && variations.length > 0 && checkVariations) {
        const addedVariationsId = Array.from(new Set(variations.map(x => x.variation_id)))
        const variationsUsed = allVariations.filter(x => addedVariationsId.includes(x.id))
        const newVariations = []
        variationsUsed.forEach(item => {
          const dataObj = window.SuperApp.actions.cloneDeep(item)
          dataObj.variation_values = variations.filter(x => dataObj.variation_values.find(y => x.variation_option_id === y))
          // TODO: Just make it single select for now TOBE used in future
          dataObj.multiple = 0
          dataObj.selectedVariations = dataObj.multiple ? [] : 0
          dataObj.variation_values.forEach(vari => {
            // eslint-disable-next-line radix
            if (parseInt(vari.option_price) === 0) {
              vari.option_price = 0
            }
          })
          newVariations.push(dataObj)
        })
        this.lastAddedProduct = props
        this.productVariations = newVariations
        isAnyAvailable = true
      }
      if (addOns && addOns.length > 0 && checkVariations) {
        this.lastAddedProduct = props
        this.productAddOns = addOns
        isAnyAvailable = true
      }
      if (isAnyAvailable && checkVariations) {
        this.showVariationsPopup()
      } else {
        isAnyAvailable = false
      }
      if (!checkVariations || !isAnyAvailable) {
        this.showVariations = false
        this.addItemToCart(props, checkVariations ? '' : this.selectedAddOns.join(','))
      }
    },
    addItemToCart(product, addOns = null) {
      this.spinDefaultObj = window.SuperApp.actions.cloneDeep(product)
      const newProduct = product.product
        ? product
        : {
            product,
            value: 1,
            type: '+',
            fromSpin: true,
          }
      this.makeCart(newProduct, addOns)
    },
    letMeChoose() {
      this.chooseManually = true
      this.setItemAddOnData(this.lastAddedProduct)
    },
    repeatLastItemAdded() {
      this.makeCart(this.lastAddedProduct)
    },
    multipleProduct(product) {
      if (this.userCartItems && this.userCartItems.carts.filter(x => x.product && x.product.id === product.id).length > 0) {
        const quantity = this.userCartItems.carts
          .filter(x => x.product && x.product.id === product.id)
          .map(x => x.quantity)
          .reduce((a, b) => a + b, 0)
        return {
          quantity,
        }
      }
      return {
        quantity: 0,
      }
    },
    makeCart(product, addOns = null) {
      let isRemove = false
      let formData = new FormData()
      const newProduct = product.product
      formData.append('item_id', newProduct.id)
      let cartProduct = null
      if (this.userCartItems && this.userCartItems.carts) {
        const allCarts = this.userCartItems.carts.filter(x => x.product && x.product.id === newProduct.id)
        cartProduct = allCarts[allCarts.length - 1]
      }
      if (this.chooseManually) {
        cartProduct = null
      }
      if (this.addedProduct(newProduct) && product.value === 0) {
        if (cartProduct) {
          isRemove = true
          this.removeItemFromCart(newProduct)
          return
        }
      }
      if (this.spinDefaultObj.type === '+' && !this.repeatLastItem && cartProduct && ((cartProduct.cartaddon && cartProduct.cartaddon.length) || cartProduct.product_data)) {
        this.lastAddedProduct = product
        this.repeatLastItem = true
        return
      }
      this.repeatLastItem = false
      if (this.addedProduct(newProduct) && !this.chooseManually) {
        this.addedProduct(newProduct).quantity = this.spinDefaultObj.type === '+' ? this.addedProduct(newProduct).quantity + 1 : this.addedProduct(newProduct).quantity - 1
      }
      formData.append('qty', this.addedProduct(newProduct) && !this.chooseManually ? this.addedProduct(newProduct).quantity : 1)
      if (this.userCartItems && this.userCartItems.carts.length > 0) {
        if (cartProduct) {
          formData.append('repeat', 1)
        }
      } else {
        formData.append('repeat', 0)
      }
      if (cartProduct) {
        if (isRemove) {
          formData = new FormData()
        }
        formData.append('cart_id', cartProduct.id)
      }
      if (this.chooseManually) {
        if (formData.has('repeat')) {
          formData.delete('repeat')
        }
        if (formData.has('cart_id')) {
          formData.delete('cart_id')
        }
        if (formData.has('qty')) {
          formData.delete('qty')
        }
        formData.append('qty', 1)
      }
      if (addOns) {
        formData.append('addons', addOns)
      }
      this.chooseManually = false
      if (this.productVariations && this.productVariations.length > 0) {
        this.productVariations.forEach(x => {
          if (!Array.isArray(x.selectedVariations)) {
            const selectedVariation = x.variation_values.find(y => y.id === x.selectedVariations)
            if (selectedVariation) {
              formData.append(`variation_option[${x.title}]`, selectedVariation.variation_option_id)
            }
          } else {
            x.selectedVariations.forEach(data => {
              // eslint-disable-next-line eqeqeq
              const selectedVariation = x.variation_values.find(y => y.id == data)
              if (selectedVariation) {
                formData.append(`variation_option[${x.title}]`, selectedVariation.variation_option_id)
              }
            })
          }
        })
      }
      formData.append('user_id', this.phoneUserInfo.id)
      if (this.phoneUserInfo.country_id) formData.append('country_id', this.phoneUserInfo.country_id)
      if (this.phoneUserInfo.country_code) formData.append('country_code', this.phoneUserInfo.country_code)
      formData.append('city_id', this.phoneUserInfo.city_id)
      if (this.editStoreInfo.promocode_id && this.editStoreInfo.orderType === 'POS') {
        formData.append('promocode_id', this.editStoreInfo.promocode_id)
      }
      const { storeAddCart } = useStoreUi()
      showLoader()
      storeAddCart(formData)
        .then(({ data }) => {
          if (data) {
            data.total_price_original = window.SuperApp.actions.cloneDeep(data.total_price)
            this.userCartItems = data
            this.originalCartItems = window.SuperApp.actions.cloneDeep(data)
            this.$emit('cart-info', this.userCartItems)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getCartList(extraParams = '') {
      if (this.phoneUserInfo && !this.phoneUserInfo.id) {
        return
      }
      const { storeCartList } = useStoreUi()
      showLoader()
      storeCartList(`?user_id=${this.phoneUserInfo.id}${extraParams}`)
        .then(({ data }) => {
          if (data && !data.message) {
            data.total_price_original = window.SuperApp.actions.cloneDeep(data.total_price)
            this.userCartItems = data
            this.originalCartItems = window.SuperApp.actions.cloneDeep(data)
            this.$emit('cart-info', this.userCartItems)
          } else {
            showDangerNotification(this, data.message)
            this.userCartItems = []
            this.originalCartItems = []
          }
          hideLoader()
        })
        .catch(error => {
          this.userCartItems = []
          this.originalCartItems = []
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-ecommerce.scss';

.menu-creation {
  .scroll-products {
    max-height: 105vh;
    overflow-y: auto;
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 50px !important;
    width: 50px !important;
    background: red;
    border-radius: 50%;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 16px;
    color: #fff;
  }
}

.wrapper-menu-options {
  width: 100%;
  display: inline-block;
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: nowrap;

  &.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }

  .menu-category-offer-cards {
    display: inline-block;
    width: 110px;
    box-shadow: none !important;
  }

  .menu-category-offer-cards.selected .category-name {
    color: green;
  }

  .category-name {
    width: 100px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inherit;
  }

  img {
    box-shadow: rgb(28 28 28 / 12%) 0px 2px 8px;
    border-radius: 20px;
    height: 100px !important;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100px !important;
  }
}

.spin-button-menu-creation {
  .mobile-p-0 {
    padding: 0 !important;
  }
}

#product-variations .custom-control-primary .custom-control-label {
  width: 100%;
}

#product-variations .modal-body {
  max-height: 75vh;
  overflow: auto;
}

#product-variations .custom-control-primary .custom-div-wrapper {
  padding-bottom: 5px !important;
}

#menuCreationModal {
  padding: 0;

  .app-collapse-body {
    padding: 0 !important;
  }

  .show-list-view {
    display: none;
  }

  .b-form-spinbutton {
    .btn-primary {
      background: transparent !important;
      color: #000000 !important;
    }
  }

  .collapse-panel-card-wrapper {
    box-shadow: none;
  }

  .collapse.show .card-body .card {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
    margin-bottom: 10px;
  }

  .ecommerce-card .item-img .zoomer img {
    height: 130px !important;
    object-fit: cover;
    width: 100% !important;
    box-shadow: rgb(28 28 28 / 12%) 0px 2px 8px;
    border-radius: 20px;
  }

  .no-store-found-image {
    width: 250px;
  }

  .modal-body {
    max-height: 90vh;
    overflow-y: auto;
    height: 90vh;
  }

  button.cart-item-qty {
    padding: 4px;
  }

  .ecommerce-cards-product {
    overflow: hidden;
    display: grid;
    grid-template-columns: 2fr 3fr;
  }

  .wrap-online-order-section .responsive-list-view {
    .show-list-view {
      display: block;
    }

    .ecommerce-card .item-img img {
      height: 165px;
      width: 100%;
      border-bottom-left-radius: calc(0.428rem - 1px);
      border-top-right-radius: 0;
    }

    .card:not(.collapse-panel-card-wrapper) {
      overflow: hidden;
      display: grid;
      grid-template-columns: 2fr 3fr;
    }

    .item-description,
    .item-company {
      display: none;
    }

    .offer-visible {
      display: block;
    }

    .item-options {
      display: block;
      margin-left: auto;
      text-align: right !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.wrap-store-review .reviews:last-child {
  border-bottom: 0 !important;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.result-toggler {
  height: 40px;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  justify-content: center;
  padding-top: 0;
}

.ecommerce-application .list-view .ecommerce-card .item-img img {
  border-top-right-radius: 0;
}

.list-view .pt-pb {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.swiper-container {
  padding-bottom: 3%;
}
.menu-creation .swiper-button-next, .menu-creation .swiper-button-prev {
	background: #7367f0;
}
.menu-creation .swiper-button-next, .menu-creation .swiper-button-prev {
	height: 25px !important;
	width: 25px !important;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
	margin-top: 5%;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
	margin-top: 5%;
}
.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
	bottom: 16px !important;
	width: 100%;
}
</style>
